import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import DesignTokens from "../components/designTokens"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Header from "../components/header"
import RichContentBlock from "../components/richContentBlock"
import TitleBlock from "../components/titleBlock"
import BenefitsBlock from "../components/benefitsBlock"
import CustomTabs from "../components/customTabs"
import { Heading, Caption } from "../components/typography"
import { Container } from "../components/utils"
import HighlightsBlock from "../components/highlightsBlock"
import TeamBlock from "../components/teamBlock"
import RichContentBlockText from "../components/richContentBlockText"
import { getAboutPath } from "../utils/path"

const AboutPage = ({ data: { page, site } }) => {
  const locale = "it"
  const i18nPaths = site.locales.map(locale => {
    return {
      locale: locale,
      value: getAboutPath(locale),
    }
  })
  return (
    <Layout locale={locale} i18nPaths={i18nPaths}>
      <SEO title={page.label} lang={locale} />
      <Header locale={locale} theme="light" title={page.title} />
      <CustomContainer>
        <Intro>
          <div>
            <Caption color={DesignTokens.colors.primary[500]}>
              {page.label}
            </Caption>
            <Heading>{page.title}</Heading>
            <RichContentBlockText html={page.text} />
          </div>
          <div>
            {page.heroImage && (
              <img src={page.heroImage.url} alt={page.heroImage.alt} />
            )}
          </div>
        </Intro>
        {page.visionMission && (
          <HighlightsBlock html={page.visionMission} title="Vision & Mission" />
        )}
      </CustomContainer>
      {page.contentTop.map(section => (
        <section key={section.id}>
          {section.model.apiKey === "rich_content_block" && (
            <RichContentBlock content={section} />
          )}
          {section.model.apiKey === "title_block" && (
            <TitleBlock content={section} />
          )}
          {section.model.apiKey === "benefits_block" && (
            <BenefitsBlock content={section} />
          )}
        </section>
      ))}
      <CustomTabs tabs={page.story} kind="stories" />
      {page.contentBottom.map(section => (
        <section key={section.id}>
          {section.model.apiKey === "rich_content_block" && (
            <RichContentBlock content={section} />
          )}
          {section.model.apiKey === "title_block" && (
            <TitleBlock content={section} />
          )}
          {section.model.apiKey === "benefits_block" && (
            <BenefitsBlock content={section} />
          )}
          {section.model.apiKey === "team_block" && (
            <TeamBlock content={section} />
          )}
        </section>
      ))}
    </Layout>
  )
}

const Intro = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  & > div {
    width: 48%;
    &:first-child {
      margin-top: ${DesignTokens.spacing[10]};
    }
    & > * {
      margin-bottom: ${DesignTokens.spacing[8]};
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  img {
    border-radius: ${DesignTokens.borderRadius};
  }
  @media screen and (max-width: 767px) {
    flex-direction: column;
    div {
      width: 100%;
      margin-top: 0;
    }
    img {
      margin: 3rem 0;
    }
  }
`

const CustomContainer = styled(Container)`
  padding-bottom: 3rem;
  & > * {
    margin-bottom: 6rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
`

export default AboutPage

export const query = graphql`
  query AboutPageQuery {
    site: datoCmsSite {
      locales
    }
    page: datoCmsAboutPage(locale: { eq: "it" }) {
      title
      label
      text
      heroImage {
        url
        alt
      }
      visionMission
      story {
        ... on DatoCmsTextBlock {
          title
          text
          id
          model {
            apiKey
          }
        }
      }
      contentTop {
        ... on DatoCmsTitleBlock {
          title
          label
          text
          id
          model {
            apiKey
          }
        }
        ... on DatoCmsBenefitsBlock {
          title
          label
          layout
          id
          backgroundColor {
            hex
          }
          benefits {
            ... on DatoCmsBenefit {
              id
              model {
                apiKey
              }
              title
              text
              icon {
                url
                alt
              }
            }
          }
          callToAction {
            ... on DatoCmsLink {
              ...LinkDetails
            }
            ... on DatoCmsExternalLink {
              id
              model {
                apiKey
              }
              anchorText
              url
              blank
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsRichContentBlock {
          ...RichContentDetails
        }
      }
      contentBottom {
        ... on DatoCmsTeamBlock {
          title
          subtitle
          people {
            bio
            id
            role
            name
            image {
              alt
              url
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsTitleBlock {
          title
          label
          text
          id
          model {
            apiKey
          }
        }
        ... on DatoCmsBenefitsBlock {
          title
          label
          layout
          id
          backgroundColor {
            hex
          }
          benefits {
            ... on DatoCmsBenefit {
              id
              model {
                apiKey
              }
              title
              text
              icon {
                url
                alt
              }
            }
          }
          callToAction {
            ... on DatoCmsLink {
              ...LinkDetails
            }
            ... on DatoCmsExternalLink {
              id
              model {
                apiKey
              }
              anchorText
              url
              blank
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsRichContentBlock {
          ...RichContentDetails
        }
      }
    }
  }
`
